import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import ProjectCard from '../components/ProjectCard';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/Seo';
import AOS from 'aos';
import "aos/dist/aos.css";


export default function Projects() {
  const projectdata = useStaticQuery(graphql`
    query ProjQuery {
      allContentfulProject(sort: {fields: recency, order: ASC}){
        nodes {
          cardTitle
          projectImage {
            file {
              url
            }
          }
          slug
        }
      }
    }
  `)

    // initializing the AOS
    React.useEffect(()=>{
      AOS.init({
        delay: 100,
        duration: 500,
      });
      AOS.refresh();
    }, []);

  return (
      <main>
        <Seo pagetitle="Projects" pagedescription="" />
        <section id="hero" className="relative pb-16 bg-cover bg-[url('../images/bgprojectsclz.jpg')] sm:pb-20 lg:hidden">
        <div><Nav pagelabel={"Our Experience"}/></div>
            <div id="hero container" className='text-gray-100'>
              {/* <div id="label" className='py-1 absolute -top-4 inset-x-0 w-8/12 mx-auto text-center rounded-md bg-green-600 font-bold text-3xl font-worksans lg:hidden'>Our Experience</div> */}
              <h1 data-aos="fade-left" data-aos-duration="600" id="title" className='w-10/12 mx-auto font-worksans text-xl text-center antialiased'><span className='text-orange-400 font-bold text-xl'>Verdant Consulting</span>'s Projects</h1>
              <p data-aos="fade-left" data-aos-duration="1100" id="description" className='pt-4 pb-8 w-9/12 mx-auto text-sm text-center'>Over the past few years, we have been able to conduct a <span className='text-orange-400'>number of projects</span> in our areas of expertise.</p>
              <div data-aos="fade-left" data-aos-duration="1600" id="cta button" className='w-1/2 mx-auto text-center'>
                  <Link to="/contact"><button className='py-2 px-5 rounded-md bg-green-700 uppercase text-center text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-600'>Contact us</button></Link>
              </div>
            </div>
        </section>

        {/* Hero - lg */}
        <div id="hero" className="hidden lg:block pb-28 h-full bg-cover bg-[url('../images/bgprojectsclz-lg.jpg')]">
        <div className='lg:pt-6 pb-20 xl:w-11/12 xl:mx-auto'><Nav/></div>
          <div id="hero content container" className='flex items-center justify-between'>
            <div id="the left space" className='w-11/12'></div>
            <div id="the right - the content" className='text-left pr-16'>
              <h1 data-aos="fade-left" data-aos-duration="600" className='font-worksans text-3xl antialiased text-white '><span className='text-orange-400 font-worksans font-bold text-3xl'>Verdant Consulting</span>'s projects </h1>
              <p data-aos="fade-left" data-aos-duration="1100" className='pt-8 pb-16 text-white pr-16 '>Over the past few years, we have been able to conduct a <span className='text-orange-400'>number of projects</span> in our areas of expertise.</p>
              <div data-aos="fade-left" data-aos-duration="1600" id="cta button" className=''>
                <Link to="/contact"><button className='py-2 px-5 rounded-md bg-green-700 uppercase text-center text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-600'>Contact us</button></Link>
              </div>
            </div>          
          </div>
          
        </div>

      {/* Our Experience*/}
        <section data-aos="fade-up" className='pt-16 pb-8 w-10/12 mx-auto sm:w-9/12 lg:pt-24 lg:pb-32 lg:w-10/12 lg:block lg:flex lg:items-bottom lg:justify-between lg:space-x-10 xl:w-9/12'>
          <div id="left side" className='lg:w-1/2'>
            <h3 className=' uppercase text-sm font-semibold text-blue-700'>our experience</h3>
            <h2 className='text-verdantblack font-worksans text-xl lg:text-3xl font-bold leading-tight tracking-tight antialiased'>Some of the Projects We Have Done in Recent Years.</h2>
            <div id="the green line" className='hidden mt-4 mb-2 border-b-2 border-green-500 lg:block'></div>
            <p className='pt-4 text-justify text-sm lg:pb-8 lg:text-base'>
              Over the past years, we have worked with both national and international institutions on a number of projects such as providing technical support in numerous ESIAs, environmental audits, stand-alone baseline studies, EMS audits, EMS implementation, and noise and air quality assessments across a variety of sectors including telecommunication, roads, heavy industry, agro-processing, tourism and hospitality, energy (hydropower), and oil and gas among others.
            </p>
            <div data-aos="fade-left" data-aos-duration="1600" id="cta button" className='hidden lg:block'>
            <Link to="#projects-section">
                <div className='w-7/12 block py-2 px-5 flex items-center justify-between rounded-md bg-green-700 uppercase text-center text-white font-bold text-sm font-ibmplex tracking-wide hover:bg-orange-600'>
                  <p>See Projects</p>
                  <div id="down-icon" className='animate-bounce'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                    </svg>
                  </div>
                </div> 
            </Link>

            </div>
          </div>
            <div id="right side - the image" className='hidden w-1/2 lg:block'>
              <StaticImage src="../images/projects - body.jpg" alt="environemntal consultants standing at a site" className="h-full rounded-lg object-bottom"/>
            </div>
        </section>

        {/* Display of Projects */}
        <section id="projects-section" className='pt-4 pb-12 w-11/12 mx-auto md:w-full lg:pt-8 lg:pb-24 xl:pt-0'>
          {/* <h2 className='py-8 text-verdantblack text-center font-worksans text-2xl font-bold leading-tight tracking-tight antialiased lg:py-16 lg:text-4xl'>All Projects</h2> */}

          <div data-aos="fade-up" id="the project cards" className='w-11/12 mx-auto grid grid-cols-2 gap-4 sm:w-10/12 md:w-9/12 md:gap-6 lg:w-10/12 lg:mx-auto xl:w-9/12 lg:grid lg:grid-cols-3 ' >
            {projectdata.allContentfulProject.nodes.map((item)=>{
              return(
                <div data-aos="zoom-in" data-aos-duration="200" className='lg:my-2'><ProjectCard title={item.cardTitle} slug={item.slug} image={item.projectImage.file.url} /></div>
              )
            })}
          </div>
        </section>

        {/* How we operate */}
        <section data-aos="fade-up" id="projects" className='lg:flex'>
          <div id="the rep image" className='lg:w-1/2'>
            <StaticImage src="../images/projects-bottom.jpg" alt="bridge with people passing on it" className='sm:h-72 lg:h-full'/>
          </div>
          <div id="projects content" className='pt-10 pb-16 bg-blue-900 lg:w-1/2'>
            <div id="container" className='w-11/12 mx-auto sm:w-9/12'>
              <h2 className='text-orange-500 text-center font-worksans text-xl font-bold leading-tight tracking-tight antialiased lg:text-left lg:text-3xl'>How We Operate.</h2>
                <p className='px-6 py-6 mt-6 mb-8 text-sm text-justify bg-blue-800 rounded-lg text-gray-100 antialiased'>Our core profession is Environmental Consultancy. We streamline compliance with National, Regional and International environmental &amp; social requirements. 
                  <br/>
                  By demonstrating to stakeholders that we meet high ethical standards in all aspects of our work, and that we are committed to helping address challenges, we are able to maintain their support. <br/>Our reputation, the standards we adhere to and our employment practices also affect our ability to attract, retain and motivate the best people.
                </p>
              <div id="cta button" className='text-center lg:text-left'>
              <Link to="/contact"><button className='py-2 px-5 rounded-md bg-green-700 uppercase text-center text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-400 lg:text-sm'>contact us</button></Link>
              </div>
            </div>
          </div>
        </section>        

        <footer><Footer/></footer>
      </main>
  )
}
