import React from 'react';
import { Link } from 'gatsby';

// props - title, image
export default function ProjectCard(props) {
    

    return (
      <main>
        <Link to={`/projects/${props.slug}`}><div id="the industry" className="pb-4 h-60 rounded-md hover:scale-105 shadow-lg sm:h-56 lg:h-72 ">
            <div className=''><img src={props.image} className="h-16 sm:h-20 w-full object-cover rounded-t-md lg:h-24 xl:h-28" alt="verdant project"/></div>
            <p className='mt-1 mb-2 py-1 px-2 w-11/12 mx-auto rounded-lg text-gray-500 bg-gray-100 font-bold text-worksans leading-tight sm:my-2 md:my-4 lg:my-2 lg:text-lg'>{props.title}</p>
            <div id="content holder" className='w-10/12 mx-auto md:w-11/12 lg:py-2'>
                <div id="green-line" className='border-b-2 border-green-500'></div>
                <div className='flex justify-between'>
                    <div></div>
                    <div id="arrow" className="hover:scale-125 py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-orange-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </div>
                </div> 
            </div>                
        </div></Link>
      </main>
  );
}
